import React from 'react';
import Footer from '../Templates/Shared/Footer';
import Header from '../Templates/Shared/Header';
// import '../styles/404.css';
import styles from '../styles/404.module.scss';
import {Helmet} from 'react-helmet';

const ErrorPage = () => {
  const locale = 'en';

  const stagingDomain = Number(process.env.StagingDomain);

  return (
    <div>
      <Helmet>
        {stagingDomain&&
        <meta name="robots" content="noindex,nofollow" />}
      </Helmet>
      <Header locale={locale} />
      <div className={styles.errorPage}>
        <div className={styles.errorContainer}>
          <div className={styles.errorTexts}>
            <p>4O4</p>
            <div className={styles.errorOops}>
              <p>Oops!</p>
            </div>
            <div className={styles.errorReason}>
              <p>We can&apos;t seem to find the page you are looking for.</p>
            </div>
          </div>
          <a href="/" className={styles.errorPageCta}>
            Go back home
          </a>
        </div>
      </div>
      <Footer />
    </div>
  );
};

ErrorPage.defaultProps = {
  data: {},
};

export default ErrorPage;
